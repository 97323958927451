import React, { useRef, useState, useEffect } from 'react';
import '../App.css';

import backgroundImage from '../assets/dark-background.png';

import videoSource1 from '../assets/tatetok-videos/ssstik.io_1725631533738.mp4';
import videoSource2 from '../assets/tatetok-videos/ssstik.io_@1ronmindset_1725626268056.mp4';
import videoSource3 from '../assets/tatetok-videos/ssstik.io_@elevatestart_1725632366537.mp4';
import videoSource4 from '../assets/tatetok-videos/ssstik.io_@imangadzhiextended_1725631548962.mp4';
import videoSource5 from '../assets/tatetok-videos/ssstik.io_@lockedin_motivation_1725632384888.mp4';
import videoSource6 from '../assets/tatetok-videos/ssstik.io_@mentality__win_1725631575126.mp4';
import videoSource7 from '../assets/tatetok-videos/ssstik.io_@nesqusq_1725629590052.mp4';
import videoSource8 from '../assets/tatetok-videos/ssstik.io_@notomotivation_1725632351042.mp4';
import videoSource9 from '../assets/tatetok-videos/ssstik.io_@officialrichteen_1725631560928.mp4';
import videoSource10 from '../assets/tatetok-videos/ssstik.io_@peakzmotivation_1725276363530.mp4';

import commentIcon from '../assets/tiktok-comment-symbol-black-23163.svg';
import shareIcon from '../assets/tiktok-share-arrow-symbol-black-23157.svg';
import saveIcon from '../assets/tiktok-save-bookmark-symbol-black-23160.svg';

export default function TateTok() {
  const containerRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false); 
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true); 

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const togglePlayPause = () => {
    const videos = containerRef.current?.querySelectorAll('video');
    if (videos) {
      const currentVideo = videos[currentIndex];
      if (isPlaying) {
        currentVideo.pause();
      } else {
        currentVideo.play().catch((error) => console.error('Error playing video:', error));
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const videos = container?.querySelectorAll('video');

    const handleScroll = () => {
      if (!container) return;  
      const scrollPosition = container.scrollTop;
      const videoHeight = container.clientHeight;
      const newIndex = Math.floor(scrollPosition / videoHeight);

      if (newIndex !== currentIndex) {
        setCurrentIndex(newIndex);
        setIsPlaying(true); 
        videos.forEach((video, index) => {
          video.muted = isMuted;
          if (index === newIndex) {
            video.play().catch((error) => console.error('Error playing video:', error));
          } else {
            video.pause();
          }
        });
      }
    };

    if (videos) {
      videos.forEach((video, index) => {
        if (index !== currentIndex) {
          video.pause();
        } else {
          video.play().catch((error) => console.error('Error playing video:', error));
        }
      });
    }

    container?.addEventListener('scroll', handleScroll);

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isMuted, currentIndex]);

  return (
    <div
      className="relative max-h-screen h-screen flex flex-col items-center overflow-hidden"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <a href='/'
        className="absolute top-5 left-5 bg-white/80 text-black font-bold py-2 px-4 rounded z-10"
      >
        Home
      </a>
      <div className="absolute inset-0 bg-black opacity-60"></div>

      <div className="relative z-10 text-white text-4xl sm:text-6xl font-extrabold mb-10 mt-10">TateTok</div>
      <div className="relative z-10 flex justify-center items-center">
      <div
        className="relative w-[270px] h-[480px] sm:w-[360px] sm:h-[640px] rounded-xl overflow-hidden container"
        style={{ overflowY: 'scroll', scrollSnapType: 'y mandatory' }}
        ref={containerRef}
        onClick={togglePlayPause}
      >
          <div className="w-full flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
            <video
              className="w-full h-full object-cover"
              src={videoSource1}
              autoPlay
              loop
              muted={isMuted}
              playsInline
            />
          </div>
          <div className="w-full flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
            <video
              className="w-full h-full object-cover"
              src={videoSource2}
              autoPlay
              loop
              muted={isMuted}
              playsInline
            />
          </div>
          <div className="w-full flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
            <video
              className="w-full h-full object-cover"
              src={videoSource3}
              autoPlay
              loop
              muted={isMuted}
              playsInline
            />
          </div>
          <div className="w-full flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
            <video
              className="w-full h-full object-cover"
              src={videoSource4}
              autoPlay
              loop
              muted={isMuted}
              playsInline
            />
          </div>
          <div className="w-full flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
            <video
              className="w-full h-full object-cover"
              src={videoSource5}
              autoPlay
              loop
              muted={isMuted}
              playsInline
            />
          </div>
          <div className="w-full flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
            <video
              className="w-full h-full object-cover"
              src={videoSource6}
              autoPlay
              loop
              muted={isMuted}
              playsInline
            />
          </div>
          <div className="w-full flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
            <video
              className="w-full h-full object-cover"
              src={videoSource7}
              autoPlay
              loop
              muted={isMuted}
              playsInline
            />
          </div>
          <div className="w-full flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
            <video
              className="w-full h-full object-cover"
              src={videoSource8}
              autoPlay
              loop
              muted={isMuted}
              playsInline
            />
          </div>
          <div className="w-full flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
            <video
              className="w-full h-full object-cover"
              src={videoSource9}
              autoPlay
              loop
              muted={isMuted}
              playsInline
            />
          </div>
          <div className="w-full flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
            <video
              className="w-full h-full object-cover"
              src={videoSource10}
              autoPlay
              loop
              muted={isMuted}
              playsInline
            />
          </div>
        </div>

        <div className="absolute top-2 left-2 z-20 cursor-pointer p-2 bg-black/50 rounded-full" onClick={toggleMute}>
          {isMuted ? (
            <svg width="24" height="24" viewBox="0 0 48 48" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M25 10.8685C25 8.47242 22.3296 7.04325 20.3359 8.37236L10.3944 15H6C4.34315 15 3 16.3431 3 18V30C3 31.6568 4.34314 33 6 33H10.3944L20.3359 39.6276C22.3296 40.9567 25 39.5276 25 37.1315V10.8685ZM29.2929 18.1213L35.1716 24L29.2929 29.8787C28.9024 30.2692 28.9024 30.9024 29.2929 31.2929L30.7071 32.7071C31.0976 33.0976 31.7308 33.0976 32.1213 32.7071L38 26.8284L43.8787 32.7071C44.2692 33.0976 44.9024 33.0976 45.2929 32.7071L46.7071 31.2929C47.0976 30.9024 47.0976 30.2692 46.7071 29.8787L40.8284 24L46.7071 18.1213C47.0976 17.7308 47.0976 17.0976 46.7071 16.7071L45.2929 15.2929C44.9024 14.9024 44.2692 14.9024 43.8787 15.2929L38 21.1716L32.1213 15.2929C31.7308 14.9024 31.0976 14.9024 30.7071 15.2929L29.2929 16.7071C28.9024 17.0976 28.9024 17.7308 29.2929 18.1213Z" />
            </svg>
          ) : (
            <svg width="24" height="24" viewBox="0 0 48 48" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.3359 8.37236C22.3296 7.04325 25 8.47242 25 10.8685V37.1315C25 39.5276 22.3296 40.9567 20.3359 39.6276L10.3944 33H6C4.34314 33 3 31.6568 3 30V18C3 16.3431 4.34315 15 6 15H10.3944L20.3359 8.37236ZM21 12.737L12.1094 18.6641C11.7809 18.8831 11.3948 19 11 19H7V29H11C11.3948 29 11.7809 29.1169 12.1094 29.3359L21 35.263V12.737ZM32.9998 24C32.9998 21.5583 32.0293 19.3445 30.4479 17.7211C30.0625 17.3255 29.9964 16.6989 30.3472 16.2724L31.6177 14.7277C31.9685 14.3011 32.6017 14.2371 33.0001 14.6195C35.4628 16.9832 36.9998 20.3128 36.9998 24C36.9998 27.6872 35.4628 31.0168 33.0001 33.3805C32.6017 33.7629 31.9685 33.6989 31.6177 33.2724L30.3472 31.7277C29.9964 31.3011 30.0625 30.6745 30.4479 30.2789C32.0293 28.6556 32.9998 26.4418 32.9998 24ZM37.0144 11.05C36.6563 11.4705 36.7094 12.0995 37.1069 12.4829C40.1263 15.3951 42.0002 19.4778 42.0002 23.9999C42.0002 28.522 40.1263 32.6047 37.1069 35.5169C36.7094 35.9003 36.6563 36.5293 37.0144 36.9498L38.3109 38.4727C38.6689 38.8932 39.302 38.9456 39.7041 38.5671C43.5774 34.9219 46.0002 29.7429 46.0002 23.9999C46.0002 18.2569 43.5774 13.078 39.7041 9.43271C39.302 9.05421 38.6689 9.10664 38.3109 9.52716L37.0144 11.05Z"
              />
            </svg>
          )}
        </div>

        <div className="absolute right-[-60px] flex flex-col justify-end items-center h-[480px] sm:h-[640px]">
          <div className="bg-white/20 p-3 rounded-full mb-4 flex justify-center items-center">
            <svg width="22" height="22" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#HeartFill_clip0)">
                <g filter="url(#HeartFill_filter0_d)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.5 2.25C10.5 2.25 12 4.25 12 4.25C12 4.25 13.5 2.25 16.5 2.25C20 2.25 22.5 4.99999 22.5 8.5C22.5 12.5 19.2311 16.0657 16.25 18.75C14.4095 20.4072 13 21.5 12 21.5C11 21.5 9.55051 20.3989 7.75 18.75C4.81949 16.0662 1.5 12.5 1.5 8.5C1.5 4.99999 4 2.25 7.5 2.25Z"
                    fill="#ffffff"
                  />
                </g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.40179 12.1998C3.58902 14.6966 5.7592 16.9269 7.74989 18.75C9.5504 20.3989 10.9999 21.5 11.9999 21.5C12.9999 21.5 14.4094 20.4072 16.2499 18.75C19.231 16.0657 22.4999 12.5 22.4999 8.49997C22.4999 8.41258 22.4983 8.32566 22.4952 8.23923C20.5671 13.6619 13.6787 18.5 11.75 18.5C10.3127 18.5 5.61087 15.8131 2.40179 12.1998Z"
                  fill="#ffffff"
                  fillOpacity="0.03"
                />
              </g>
              <defs>
                <filter
                  id="HeartFill_filter0_d"
                  x="-0.9"
                  y="1.05"
                  width="25.8"
                  height="24.05"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></feColorMatrix>
                  <feOffset dy="1.2"></feOffset>
                  <feGaussianBlur stdDeviation="1.2"></feGaussianBlur>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                </filter>
                <clipPath id="HeartFill_clip0">
                  <rect width="24" height="24" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>

          <div className="bg-white/20 p-3 rounded-full mb-4 flex justify-center items-center">
            <img src={commentIcon} alt="Tiktok Comment" />
          </div>

          <div className="bg-white/20 p-3 rounded-full mb-4 flex justify-center items-center">
            <img src={saveIcon} alt="Tiktok Save" />
          </div>

          <div className="bg-white/20 p-3 rounded-full mb-4 flex justify-center items-center">
            <img src={shareIcon} alt="Tiktok Share" />
          </div>

          <div className="bg-white/20 p-3 rounded-full mb-4 flex justify-center items-center">
            <svg fill="#ffffff" width="22" height="22" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 24a5 5 0 1 1 10 0 5 5 0 0 1-10 0ZM19 24a5 5 0 1 1 10 0 5 5 0 0 1-10 0ZM39 19a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
