import React from 'react';
import './App.css';
import { Link } from "react-router-dom";

import leftCloudImage from '../src/assets/AT Image (1).png';
import rightCloudImage from '../src/assets/CK Image (1).png';
import logoImage from '../src/assets/$BOP logo transparent.png';
import xIcon from '../src/assets/X_logo_2023.svg';
import pumpfunIcon from '../src/assets/icon.png';
import telegramIcon from '../src/assets/iconmonstr-telegram-1 (1).svg';
import backgroundMusic from '../src/assets/ProdByOsaka - The Bop Anthem (Jersey Club Remix)(STADIUM VERSION).mp3';
import warroomImage from '../src/assets/warroom.jpg';

export default function LandingPage() {
  return (
    <div id="landing-page" className="font-museo relative min-h-screen h-full flex justify-center overflow-hidden">
      <div className="absolute inset-0 flex">
        <div id="landing-page" className="w-1/2 bg-gradient-to-r from-purple-500 via-blue-500 to-indigo-500"></div>
        <div
          className="w-1/2"
          style={{
            backgroundImage: `url(${warroomImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        ></div>
      </div>

      <div className="absolute top-3 left-3 sm:top-5 sm:left-5 text-base sm:text-lg font-bold text-white bg-white/20 sm:p-3 rounded-lg z-40">
        CA: 23y2huB1mmmFM68t1Dbj9MJaRBPvZdj5tADnts9cpump     </div>

      <img
        id="left-cloud"
        src={leftCloudImage}
        className="absolute right-[-100px] top-[200px] sm:top-[140px] w-[250px] md:w-[480px] sm:w-[360px] z-10"
        alt="Person on Cloud Left"
      />
      <img
        id="right-cloud"
        src={rightCloudImage}
        className="absolute left-[0px] top-[200px] sm:top-[140px] w-[250px] md:w-[480px] sm:w-[360px] z-10"
        alt="Person on Cloud Right"
      />

      <div className="flex flex-col items-center z-40">
        <img src={logoImage} alt="Logo" className="w-48 md:w-80 sm:w-64 pulsing-logo" />
        <div className="flex justify-center mt-48 sm:mt-28 space-x-28 md:space-x-64 sm:space-x-44 p-4">
          <Link to={"/boptok"}
            id="wrong-answer-button"
            className="bg-gradient-to-tr from-[#29FBE9] via-[#F737BE] to-[#FAF200] text-white font-extrabold text-sm md:text-2xl sm:text-xl py-1 px-3 sm:py-3 sm:px-8 rounded-md shadow-2xl transform hover:scale-105 transition-transform duration-300 rattle-button"
          >
            Keep Funding OF Girls
          </Link>
          <Link to={"/tatetok"}
            className="bg-gradient-to-tr from-slate-700 to-slate-900 text-white text-sm md:text-2xl sm:text-xl font-extrabold py-1 px-3 sm:py-3 sm:px-8 rounded-md shadow-2xl transform hover:scale-105 transition-transform duration-300 scroll-to-video rattle-button"
          >
            LOCK IN.
          </Link>
        </div>
        <div className="flex justify-center mt-28 sm:mt-28 space-x-8">
          <a
            href="https://x.com/boponsol_" // HERE
            className="flex items-center justify-center bg-white/50 rounded-full p-4 hover:bg-white/70 transition-colors duration-300"
          >
            <img src={xIcon} alt="X Icon" className="w-5 h-5" />
          </a>
          <a
            href="https://pump.fun/23y2huB1mmmFM68t1Dbj9MJaRBPvZdj5tADnts9cpump" // HERE
            className="flex items-center justify-center bg-white/50 rounded-full p-4 hover:bg-white/70 transition-colors duration-300"
          >
            <img src={pumpfunIcon} alt="Pumpfun Icon" className="w-5 h-5" />
          </a>
          <a
            href="https://t.me/BOPONSOLANA" // HERE
            className="flex items-center justify-center bg-white/50 rounded-full p-4 hover:bg-white/70 transition-colors duration-300"
          >
            <img src={telegramIcon} alt="Telegram Icon" className="w-5 h-5" />
          </a>
        </div>
      </div>

      <audio id="background-music" autoPlay loop>
        <source src={backgroundMusic} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}
